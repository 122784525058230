import * as React from "react";
import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";


import {
  makeStyles}
 from "@mui/styles";


import { useDispatch, useSelector } from "react-redux";
import {
  getAgencyproperties,
  saveAgencyproperties,
} from "../features/dataApiCalls";
import { setAgencyproperties } from "../features/dataSlice";

const useStyles = makeStyles(theme => ({
  textarea: {
    resize: "both"
  }
}));


export default function SettingsPage(props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const agencyproperties = useSelector((state) => state.data.agencyproperties);
  const agencyid = useSelector((state) => state.system.agencyid);
  const role = useSelector((state) => state.system.role);

  React.useEffect(() => {
    dispatch(getAgencyproperties());
  }, [agencyid]);

  React.useEffect(() => {
    dispatch(getAgencyproperties());
  }, []);

  let propertiesParseable = false;

  if (
    agencyproperties?.properties &&
    agencyproperties.properties instanceof Object &&
    JSON.stringify(agencyproperties.properties).indexOf("{") === 0
  ) {
    propertiesParseable = true;
  }

  let smtpParseable = false;

  if (
    agencyproperties?.smtp_properties &&
    agencyproperties.smtp_properties instanceof Object &&
    JSON.stringify(agencyproperties.smtp_properties).indexOf("{") === 0
  ) {
    smtpParseable = true;
  }

  return (
    <Paper style={{ padding: 16 }}>
      <div style={{ display: "flex" }}>
        <div>ID:{agencyid}</div>
        <div style={{ flexGrow: 1 }}></div>

        <div>
          <ButtonGroup style={{ marginBottom: 16 }} variant="contained">
            <Button onClick={() => dispatch(getAgencyproperties())}>
              Get Data
            </Button>
            {(role == "admin" || role == "master") && (
              <>
                <Button
                  disabled={!(propertiesParseable && smtpParseable)}
                  onClick={() => dispatch(saveAgencyproperties())}
                >
                  Save Data
                </Button></>)}
          </ButtonGroup>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="email"
            value={agencyproperties.email ? agencyproperties.email : ""}
            onChange={(evt) => {
              let act = { ...agencyproperties };
              act.email = evt.target.value;
              dispatch(setAgencyproperties(act));
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="website"
            value={agencyproperties.website ? agencyproperties.website : ""}
            onChange={(evt) => {
              let act = { ...agencyproperties };
              act.website = evt.target.value;
              dispatch(setAgencyproperties(act));
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            style={{
              backgroundColor: propertiesParseable ? undefined : "#ffaaaa",
            }}
            fullWidth
            multiline
            rows={4}
            label="properties"
            inputProps={{ className: classes.textarea }}
            value={
              agencyproperties.properties
                ? propertiesParseable
                  ? JSON.stringify(agencyproperties.properties,null, 8)
                  : agencyproperties.properties
                : ""
            }
            onChange={(evt) => {
              let act = { ...agencyproperties };
              try {
                act.properties = JSON.parse(evt.target.value);
              } catch (e) {
                act.properties = evt.target.value;
              }

              dispatch(setAgencyproperties(act));
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            style={{ backgroundColor: smtpParseable ? undefined : "#ffaaaa" }}
            fullWidth
            multiline
            rows={4}
            inputProps={{ className: classes.textarea }}
            label="smtp"
            value={
              agencyproperties.smtp_properties
                ? smtpParseable
                  ? JSON.stringify(agencyproperties.smtp_properties,null, 8)
                  : agencyproperties.smtp_properties
                : ""
            }
            onChange={(evt) => {
              let act = { ...agencyproperties };
              try {
                act.smtp_properties = JSON.parse(evt.target.value);
              } catch (e) {
                act.smtp_properties = evt.target.value;
              }
              dispatch(setAgencyproperties(act));
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            multiline
            rows={6}
            label="signature"
            inputProps={{ className: classes.textarea }}
            value={agencyproperties.signature ? agencyproperties.signature : ""}
            onChange={(evt) => {
              let act = { ...agencyproperties };
              act.signature = evt.target.value;
              dispatch(setAgencyproperties(act));
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            multiline
            rows={6}
            inputProps={{ className: classes.textarea }}
            label="signature html"
            value={
              agencyproperties.signature_html
                ? agencyproperties.signature_html
                : ""
            }
            onChange={(evt) => {
              let act = { ...agencyproperties };
              act.signature_html = evt.target.value;
              dispatch(setAgencyproperties(act));
            }}
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <TextField
            fullWidth
            multiline
            rows={6}
            inputProps={{ className: classes.textarea }}
            label="vcf"
            value={
              agencyproperties.vcf
                ? agencyproperties.vcf
                : ""
            }
            onChange={(evt) => {
              let act = { ...agencyproperties };
              act.vcf = evt.target.value;
              dispatch(setAgencyproperties(act));
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
         
          <img src={agencyproperties.properties?.vcfcode} width="150px"></img>
          {agencyproperties.properties?.vcfcode}
     
        </Grid>
      </Grid>
    </Paper>
  );
}
